import { DateTime } from 'luxon'
import { onlyDigits } from './index.js'

const MAX_YEARLY_GROSS_REVENUE = 999999999.99

export const isCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    if (!cnpj) return false

    if (cnpj.length !== 14) return false

    // Eliminates invalid CNPJs
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    )
        return false

    // Validate DVs
    let size = cnpj.length - 2
    let numbers = cnpj.substring(0, size)
    let digits = cnpj.substring(size)
    let sum = 0
    let pos = size - 7
    for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * pos--
        if (pos < 2) pos = 9
    }
    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)
    if (result != Number(digits.charAt(0))) return false

    size = size + 1
    numbers = cnpj.substring(0, size)
    sum = 0
    pos = size - 7
    for (let i = size; i >= 1; i--) {
        sum += Number(numbers.charAt(size - i)) * pos--
        if (pos < 2) pos = 9
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

    return result == Number(digits.charAt(1))
}

export const isCpf = (cpf) => {
    const c = cpf.replace(/[^\d]/g, '')

    if (c.length !== 11) {
        return false
    }

    if (c === '00000000000') {
        return false
    }

    let r
    let s = 0

    for (let i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i)

    r = (s * 10) % 11

    if (r === 10 || r === 11) {
        r = 0
    }

    if (r !== parseInt(c[9])) return false
    s = 0

    for (let i = 1; i <= 10; i++) {
        s = s + parseInt(c[i - 1]) * (12 - i)
    }

    r = (s * 10) % 11

    if (r === 10 || r === 11) {
        r = 0
    }

    return r === parseInt(c[10])
}

const RULES_WITH_PARAM = ['minLength', 'exactLength']
const RULES_WITH_TWO_PARAMS = ['realBetween', 'numberBetween']

const DEFAULT_RULES_MESSAGES = {
    cpf: 'CPF inválido',
    cnpj: 'CNPJ inválido',
    cpfCnpj: 'CPF/CNPJ inválido',
    bankAgency: 'Agência inválida',
    bankAccount: 'Conta inválida',
    bankAccountDigit: 'Dígito inválido',
    required: 'Por favor, preencha este campo',
    equal: 'Campos não conferem',
    date: 'Data inválida',
    fullName: 'Por favor, insira nome e sobrenome',
    phone: 'Por favor, complete o telefone',
    email: 'E-mail inválido',
    emailFull: 'E-mail formato inválido',
    minLength: 'Campo deve ter ao menos ${0} dígitos',
    exactLength: 'Campo deve ter ${0} dígitos',
    realBetween: 'Valor deve estar entre R$${0} e R$${1}',
    numberBetween: 'Valor deve estar entre ${0} e ${1}',
    boolean: 'Selecione uma opção',
    name: 'Nome inválido',
    zipCode: 'CEP inválido',
    birthDate: 'Data de nascimento inválida',
    positiveNumber: 'Valor deve estar entre R$0 e R$999.999.999,99',
    barcode: 'Código de barras inválido',
    tomorrowDate: 'Data escolhida deve ser a partir de amanhã',
    threeMonthsFromNow: 'Data escolhida deve estar dentro de um período de três meses.'
}

const isRequired = (field) => {
    return field.required
}

const isOptional = (value, field) => {
    return !isRequired(field) && (!value || value === '')
}

const RULES = {
    cpf: isCpf,
    cnpj: isCnpj,
    required: (value) => {
        return value && value !== ''
    },
    boolean: (value) => {
        return value !== undefined && value !== null
    },
    equal: (value, { data, options }) => {
        let key = options.field
        return data[key] && value === data[key]
    },
    date: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        if (options.format === 'utc') {
            return DateTime.fromISO(value).isValid
        } else {
            return DateTime.fromFormat(value, options.format).isValid
        }
    },
    fullName: (value, { field }) => {
        if (isOptional(value, field)) return true
        return value && value.trim().split(' ').length > 1
    },
    phone: (value, { field }) => {
        if (isOptional(value, field)) return true
        return value && (value.length === 11 || value.length === 10)
    },
    email: (value, { field }) => {
        if (isOptional(value, field)) return true
        return value && value.includes('@')
    },
    emailFull: (value, { field }) => {
        if (isOptional(value, field)) return true
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return value && emailRegex.test(value)
    },
    name: (value, { field }) => {
        if (isOptional(value, field)) return true
        if (typeof value !== 'string') {
            return false
        }
        return value && !/\d/.test(value)
    },
    zipCode: (value, { field }) => {
        if (isOptional(value, field)) return true
        const zipCodeRegex = /^\d{8}$/
        return value && zipCodeRegex.test(value)
    },
    birthDate: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        let parsedDate
        if (options.format === 'utc') {
            parsedDate = DateTime.fromISO(value)
        } else {
            parsedDate = DateTime.fromFormat(value, options.format)
        }
        const currentDate = DateTime.now()
        const ageValidation = currentDate.minus({ years: options.minAge })
        return parsedDate.isValid && parsedDate <= ageValidation
    },
    minLength: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        return value && value.length >= options.val
    },
    exactLength: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        return value && value.length === options.val
    },
    realBetween: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        return value && value >= options.val[0] && value <= options.val[1]
    },
    numberBetween: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        return value && value >= options.val[0] && value <= options.val[1]
    },
    positiveNumber: (value, { field }) => {
        if (isOptional(value, field)) return true
        return value > 0 && value <= MAX_YEARLY_GROSS_REVENUE
    },
    cpfCnpj: (value) => {
        if (!value) return false
        if (value?.length === 11) {
            return isCpf(value)
        } else if (value?.length === 14) {
            return isCnpj(value)
        } else return false
    },
    bankAgency: (value) => {
        if (!value) return false
        return value?.length === 4
    },
    bankAccount: (value) => {
        if (!value) return false
        return value?.length >= 4 && value.length <= 11 ? true : false
    },
    bankAccountDigit: (value) => {
        if (!value) return false
        return value?.length === 1 ? true : false
    },
    barcode: (value) => {
        return value?.length >= 35 && value?.length <= 60 ? true : false
    },
    tomorrowDate: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        let parsedDate
        if (options.format === 'utc') {
            parsedDate = DateTime.fromISO(value)
        } else {
            parsedDate = DateTime.fromFormat(value, options.format)
        }
        const currentDate = DateTime.now()
        const tomorrowValidation = currentDate.plus({ days: 1 }).startOf('day')
        return parsedDate.isValid && parsedDate >= tomorrowValidation
    },
    threeMonthsFromNow: (value, { field, options }) => {
        if (isOptional(value, field)) return true
        if (!value) return false
        let parsedDate
        if (options.format === 'utc') {
            parsedDate = DateTime.fromISO(value)
        } else {
            parsedDate = DateTime.fromFormat(value, options.format)
        }
        const currentDate = DateTime.now()
        const threeMonthsFromNow = currentDate.plus({ months: 3 }).endOf('day')
        return (
            parsedDate.isValid &&
            parsedDate >= currentDate.startOf('day') &&
            threeMonthsFromNow > parsedDate
        )
    }
}

/**
 *
 * {
 *   "cnpj": {
 *      rules: ["required", "cnpj"],
 *      messages: {
 *        "required": "Campo requerido",
 *      }
 *   }
 * }
 * @param settings
 */
export const validatorModel = (settings = {}) => {
    const fields = {}
    for (const key in settings) {
        const field = settings[key]
        const fieldMessages = field.messages || {}
        let required = false

        if (!field.rules || field.rules.length === 0) {
            continue
        }
        const rules = field.rules.map((data) => {
            let ruleName = typeof data === 'string' ? data : data.rule
            let message = fieldMessages[ruleName] || DEFAULT_RULES_MESSAGES[ruleName] || ''

            if (ruleName === 'required') required = true
            if (RULES_WITH_PARAM.includes(ruleName) && data.val)
                message = message.replace('${0}', data.val)
            if (RULES_WITH_TWO_PARAMS.includes(ruleName) && data.val) {
                const first = data.val[0].toLocaleString(navigator.language, {
                    minimumFractionDigits: 2
                })
                const second = data.val[1].toLocaleString(navigator.language, {
                    minimumFractionDigits: 2
                })
                message = message.replace('${0}', first).replace('${1}', second)
            }

            return {
                ruleName: ruleName,
                options: typeof data === 'object' ? data : {},
                validator: RULES[ruleName],
                message
            }
        })

        fields[key] = { rules, required }
    }

    const validate = (data = {}, keys = null) => {
        let result = true
        let errorMessages = {}
        if (keys == null || keys.length === 0) {
            keys = Object.keys(fields)
        }
        for (const key of keys) {
            const field = fields[key]
            if (!data[key] && field.required) {
                errorMessages[key] = field.rules.find((r) => r.ruleName === 'required')?.message
                result = false
                continue
            }

            for (const rule of fields[key].rules) {
                if (!rule.validator(data[key], { field, data, options: rule.options })) {
                    errorMessages[key] = rule.message
                    result = false
                    break
                }
            }
        }

        return { result, errorMessages }
    }
    return { fields, validate }
}

export const isEmail = (email) => {
    if (typeof email !== 'string') {
        return false
    }
    return /^(?=[a-z0-9@.!#$%&'*+/=?^_`{|}~-]{6,254}$)(?=[a-z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@)[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-z0-9-]{1,63}$)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
        email
    )
}

export const sameDay = (a, b) => {
    return a.hasSame(b, 'day') && a.hasSame(b, 'month') && a.hasSame(b, 'year')
}

export const isValidBrazilianPhoneNumber = (phone) => {
    const digits = onlyDigits(phone)
    const startsWithCountryCode = digits.startsWith('55')
    return digits.length === 13 && startsWithCountryCode
}

export const documentDynamicTextField = (document) => {
    if (onlyDigits(document)?.length <= 11) {
        return 'CPF'
    } else {
        return 'CNPJ'
    }
}

export const isDateInFuture = (dateString) => {
    const endOfDay = DateTime.now().endOf('day')
    const date = DateTime.fromISO(dateString)
    return endOfDay <= date
}

export const PasswordRuleKeys = Object.freeze({
    MINIMUM_8_MAXIMUM_20: 'MINIMUM_8_MAXIMUM_20',
    AT_LEAST_ONE_NUMBER: 'AT_LEAST_ONE_NUMBER',
    UPPERCASE_AND_LOWERCASE: 'UPPERCASE_AND_LOWERCASE',
    AT_LEAST_ONE_SPECIAL_CHARACTER: 'AT_LEAST_ONE_SPECIAL_CHARACTER'
})

export const passwordRules = Object.freeze({
    [PasswordRuleKeys.MINIMUM_8_MAXIMUM_20]: {
        text: 'Entre 8 e 20 caracteres',
        validator: (password) => password.length >= 8 && password.length <= 20
    },
    [PasswordRuleKeys.AT_LEAST_ONE_NUMBER]: {
        text: 'Inclua pelo menos um número',
        validator: (password) => /\d/.test(password)
    },
    [PasswordRuleKeys.UPPERCASE_AND_LOWERCASE]: {
        text: 'Inclua pelo menos uma letra maiúscula e uma letra minúscula',
        validator: (password) => /[A-Z]/.test(password) && /[a-z]/.test(password)
    },
    [PasswordRuleKeys.AT_LEAST_ONE_SPECIAL_CHARACTER]: {
        text: 'Inclua pelo menos um caractere especial (@, #, $, etc.).',
        validator: (password) => /[!@#$%^&*(),.?":;{}|<>[\]\\/'`~+=_-]/g.test(password)
    }
})
